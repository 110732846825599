import localForage from 'localforage';

export default {
  async loadInfo({ commit, rootState }, payload) {
    if (!payload) payload = await localForage.getItem('userInfo');
    else await localForage.setItem('userInfo', payload);
    const selectedStoreToBeUpdate = rootState.selectedStore;

    if (selectedStoreToBeUpdate) {
      const store = payload.user.store.find(
        (s) =>
          s.storeId === selectedStoreToBeUpdate.storeId &&
          s.marketplaceName === selectedStoreToBeUpdate.marketplaceName
      );
      store && commit('SET_SELECTED_STORE', store, { root: true });
    }
    if (payload) {
      const accountType = selectedStoreToBeUpdate
        ? selectedStoreToBeUpdate.accountType
        : payload?.user?.accountType || 0;

      payload.user.packages =
        accountType === 0
          ? payload?.user?.allPackages?.AllPackages?.sellerPackageList || []
          : payload?.user?.allPackages?.AllPackages?.agencyPackageList || [];
      payload.readOnly = false;
      payload?.user?.store?.sort((a, b) =>
        a.storeName.localeCompare(b.storeName)
      );
    }
    commit('SET_USER_INFO', payload);
    rootState.AppActiveUser = { ...payload };
  },
  async removeUserInfo({ commit }) {
    await localForage.removeItem('userInfo');
    commit('SET_USER_INFO', null);
  },
};

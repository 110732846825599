import api from '@/api';
import axios from 'axios';

const initialDownloadState = {
  taskId: null,
  templateType: 1,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
let canceler = null;
canceler?.();

export default {
  checkDownloadStatus({ dispatch, getters }, { templateType }) {
    const download = getters.download(templateType);

    if (!download?.taskId || ['ERROR', 'SUCCESS'].includes(download.status))
      return;
    api.bearer
      .post(
        { taskId: download.taskId, templateType: download.templateType },
        `vendor/template-task-status`,
        new axios.CancelToken((c) => {
          canceler = c;
        }),
        true
      )
      .then((response) => {
        const {
          data: {
            ApiStatusCode,
            ApiStatusMessage,
            Data: { status, errorMessage, downloadFileName },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkDownloadStatus', { templateType });
            }, 15000);
            return;
          case 2:
            dispatch('setDownload', {
              templateType,
              download: {
                url: downloadFileName,
                status: 'SUCCESS',
                templateType: download.templateType,
              },
            });
            return;
          case 3:
          default:
            throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearDownload', { templateType });
      });
  },
  clearDownload({ dispatch }, { templateType }) {
    dispatch('setDownload', {
      templateType,
      willReset: true,
    });
  },
  setDownload(
    { commit, rootGetters },
    { templateType, download, willReset = false }
  ) {
    const vendorId = rootGetters.getVendorId;
    commit('SET_DOWNLOAD', { templateType, vendorId, download, willReset });
  },
  async downloadTemplate(
    { dispatch, rootGetters },
    { templateType, customDateData, day }
  ) {
    try {
      dispatch('setDownload', {
        templateType,
        download: {
          ...initialDownloadState,
          templateType,
          status: 'PENDING',
        },
      });
      const response = await api.bearer.post(
        {
          templateType: templateType,
          vendorId: rootGetters.getVendorId,
          day: day,
          customDateData: customDateData,
        },
        `vendor/export-template-excel`,
        new axios.CancelToken((c) => {
          canceler = c;
        }),
        true
      );
      const {
        ApiStatus,
        ApiStatusMessage,
        Data: { UUID },
      } = response.data;
      if (!ApiStatus) throw new Error(ApiStatusMessage);
      dispatch('setDownload', {
        templateType,
        download: {
          taskId: UUID,
          templateType: templateType,
          requestTime: new Date(),
        },
      });
      dispatch('checkDownloadStatus', { templateType });
    } catch (error) {
      console.log(error);
      dispatch('clearDownload', { templateType });
    }
  },
};

export default {
  SET_DOWNLOAD(
    state,
    { marketplace, evaStoreId, download, willReset = false }
  ) {
    if (willReset) {
      state.download[marketplace] = {
        ...state.download[marketplace],
        [evaStoreId]: null,
      };
      delete state.download[marketplace][evaStoreId];
    } else {
      state.download[marketplace] = {
        ...state.download[marketplace],
        [evaStoreId]: {
          ...state.download[marketplace][evaStoreId],
          ...download,
        },
      };
    }
    localStorage.setItem(
      `download-${marketplace}`,
      JSON.stringify(state.download[marketplace])
    );
  },
  SET_UPLOAD(state, { marketplace, evaStoreId, upload, willReset = false }) {
    if (willReset) {
      state.upload[marketplace] = {
        ...state.upload[marketplace],
        [evaStoreId]: null,
      };
      delete state.upload[marketplace][evaStoreId];
    } else {
      state.upload[marketplace] = {
        ...state.upload[marketplace],
        [evaStoreId]: {
          ...state.upload[marketplace][evaStoreId],
          ...upload,
        },
      };
    }
    localStorage.setItem(
      `upload-${marketplace}`,
      JSON.stringify(state.upload[marketplace])
    );
  },
  SET_STORES(state, { stores, marketplace }) {
    state.marketplaces[marketplace].stores = stores;
    state.marketplaces[marketplace].lastFetchedAt = Date.now();
  },
  SET_UPDATING_STORE(state, { marketplace, oStore }) {
    state.marketplaces[marketplace].updatingStore = oStore;
  },
  UPDATE_STORE(state, { marketplace, oStore }) {
    let storeToUpdate = state.marketplaces[marketplace].stores.find((s) => {
      if (marketplace === 'shopify') {
        return s.shopifyShopName === oStore.shopifyShopName;
      } else if (marketplace === 'walmart') {
        return (
          s.walmartMarketName === oStore.walmartMarketName &&
          s.walmartPartnerId === oStore.walmartPartnerId
        );
      }
      return {};
    });
    if (storeToUpdate) {
      Object.assign(storeToUpdate, oStore);
    }
  },
  REMOVE_STORE(state, { marketplace, oStore }) {
    state.marketplaces[marketplace].stores = state.marketplaces[
      marketplace
    ].stores.filter((store) => {
      if (marketplace === 'shopify') {
        return store.shopifyShopName !== oStore.shopifyShopName;
      } else if (marketplace === 'walmart') {
        return (
          store.walmartMarketName !== oStore.walmartMarketName &&
          store.walmartPartnerId !== oStore.walmartPartnerId
        );
      }
      return false;
    });
  },
};

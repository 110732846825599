/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

import moduleAuth from './auth/moduleAuth.js';
import moduleAdvertising from './advertising/moduleAdvertising';
import moduleParentAsinTemplate from './parentAsinTemplate/moduleParentAsinTemplate';
import moduleIntegrations from './integrations/moduleIntegrations';
import moduleUserInfo from './user-info/moduleUserInfo';
import moduleRenewToken from './renewToken/moduleRenewToken';
import modulePdfTemplate from './pdf-template/modulePdfTemplate';
import moduleSkuPerformance from './sku-performance/moduleSkuPerformance';
import moduleVendor from './vendor/moduleVendor';
import moduleSimilarProduct from './similar-product/moduleSimilarProduct';
import moduleAdminPanel from './admin-panel/moduleAdminPanel';
import moduleManageVendor from './manage-vendor/moduleManageVendor';
import moduleManageDimension from './manage-dimension/moduleManageDimension';
import moduleSettings from './settings/moduleSettings';
import moduleReportingDashboard from './reporting-dashboard/moduleReportingDashboard';
import moduleLinkAccount from './link-account/moduleLinkAccount';
import moduleExecutiveDashboard from './executive-dashboard/moduleExecutiveDashboard';
import moduleWalmart from './walmart/moduleWalmart';
import modulePackagesAndOrderForm from './packages-and-order-form/modulePackagesAndOrderForm';
import moduleFilters from './filters/moduleFilters';
import moduleTempFilters from './temp-filters/moduleFilters';
import moduleDailyTask from './daily-task/moduleDailyTask';
import moduleWalmartAdvertising from './walmart/advertising/module.js';

export default async () => {
  let stateAwaited = await state();

  return new Vuex.Store({
    getters,
    mutations,
    state: stateAwaited,
    actions,
    modules: {
      auth: moduleAuth,
      advertising: moduleAdvertising,
      parentAsinTemplate: moduleParentAsinTemplate,
      integrations: moduleIntegrations,
      userInfo: moduleUserInfo,
      renewToken: moduleRenewToken,
      pdfTemplate: modulePdfTemplate,
      skuPerformance: moduleSkuPerformance,
      vendor: moduleVendor,
      similarProduct: moduleSimilarProduct,
      adminPanel: moduleAdminPanel,
      manageVendor: moduleManageVendor,
      manageDimension: moduleManageDimension,
      settings: moduleSettings,
      reportingDashboard: moduleReportingDashboard,
      linkAccount: moduleLinkAccount,
      executiveDashboard: moduleExecutiveDashboard,
      walmart: moduleWalmart,
      packagesAndOrderForm: modulePackagesAndOrderForm,
      filters: moduleFilters,
      tempFilters: moduleTempFilters,
      dailyTask: moduleDailyTask,
      walmartAdvertising: moduleWalmartAdvertising,
    },
    strict: false,
  });
};

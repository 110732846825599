import {
  adminDashboardOnlyAccountPermissions,
  adminPanelAccountPermissions,
} from '@/constants/pages.ts';

export default {
  uInfo: (state) => state.userInfo,
  currentUserPermissionCode: (state) => {
    const currentPermissions = state?.userInfo?.user?.userPermissionList;

    for (let permission of currentPermissions) {
      if (
        adminDashboardOnlyAccountPermissions.includes(permission.PermissionCode)
      ) {
        return permission.PermissionCode;
      }
    }
    return undefined;
  },
  showTabWithPermission: (_, getters) => {
    return (tabName) =>
      adminPanelAccountPermissions[tabName]?.view.includes(
        getters.currentUserPermissionCode
      );
  },
  getAccountType: (_, __, rootGetters) => {
    return rootGetters.selectedStore?.accountType;
  },
};

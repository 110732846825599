export default {
  download: {
    shopify: JSON.parse(localStorage.getItem('download-shopify')) ?? {},
    walmart: JSON.parse(localStorage.getItem('download-walmart')) ?? {},
  },
  upload: {
    shopify: JSON.parse(localStorage.getItem('upload-shopify')) ?? {},
    walmart: JSON.parse(localStorage.getItem('upload-walmart')) ?? {},
  },
  marketplaces: {
    shopify: {
      stores: [],
      lastFetchedAt: null,
      updatingStore: null,
    },
    walmart: {
      stores: [],
      lastFetchedAt: null,
      updatingStore: null,
    },
  },
};

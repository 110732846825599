export const frequencyEnums = [
  {
    text: 'One Time',
    value: 1,
  },
  {
    text: 'Recurring',
    value: 2,
  },
];

export const intervalEnums = [
  {
    text: 'Daily',
    value: 2,
  },
  {
    text: 'Weekly',
    value: 3,
  },
  {
    text: 'Monthly',
    value: 4,
  },
];

export const frequencyForBackend = [
  {
    text: 'One Time',
    value: 1,
  },
  {
    text: 'Daily',
    value: 2,
  },
  {
    text: 'Weekly',
    value: 3,
  },
  {
    text: 'Monthly',
    value: 4,
  },
];

export const averageOfEnums = [
  {
    text: '5 days',
    value: 5,
  },
  {
    text: '7 days',
    value: 6,
  },
];

export const dayEnums = [
  {
    text: 'Monday',
    value: 1,
  },
  {
    text: 'Tuesday',
    value: 2,
  },
  {
    text: 'Wednesday',
    value: 3,
  },
  {
    text: 'Thursday',
    value: 4,
  },
  {
    text: 'Friday',
    value: 5,
  },
  {
    text: 'Saturday',
    value: 6,
  },
  {
    text: 'Sunday',
    value: 7,
  },
];

export const reportTypes = [
  {
    id: 1,
    name: 'Best Seller Rank Report',
    filterName: 'best-seller-rank',
    configs: {
      frequency: [frequencyEnums[1]],
      interval: [intervalEnums[1]],
      averageOf: [],
      compareStartDay: dayEnums,
      compareEndDay: dayEnums,
      hasNegativeSwitch: false,
      dateRange: false,
      createOn: dayEnums,
      endDate: true,
    },
  },
  {
    id: 2,
    name: 'Profit Details Report',
    filterName: 'profit-details',
    configs: {
      frequency: [frequencyEnums[0], frequencyEnums[1]],
      interval: [intervalEnums[0], intervalEnums[1], intervalEnums[2]],
      averageOf: [],
      compareStartDay: [],
      compareEndDay: [],
      hasNegativeSwitch: false,
      dateRange: true,
      reportPeriodDateRange: true,
      reportPeriodDateType: 'date',
      reportPeriodDateFormat: 'YYYY-MM-DD',
      createOn: dayEnums,
      endDate: true,
    },
  },
  {
    id: 3,
    name: 'SKU Orders Report',
    filterName: 'sku-order-details',
    configs: {
      frequency: [frequencyEnums[1]],
      interval: [intervalEnums[0], intervalEnums[2]],
      averageOf: [],
      compareStartDay: [],
      compareEndDay: [],
      hasNegativeSwitch: false,
      dateRange: false,
      createOn: [],
      endDate: true,
    },
  },
  {
    id: 4,
    name: 'Order Details Report',
    filterName: 'order-details',
    configs: {
      frequency: [frequencyEnums[0], frequencyEnums[1]],
      interval: [intervalEnums[0], intervalEnums[1], intervalEnums[2]],
      averageOf: [],
      compareStartDay: [],
      compareEndDay: [],
      hasNegativeSwitch: false,
      dateRange: true,
      reportPeriodDateRange: true,
      reportPeriodDateType: 'date',
      reportPeriodDateFormat: 'YYYY-MM-DD',
      reportPeriodMaxMonthsBackward: 3,
      createOn: dayEnums,
      endDate: true,
    },
  },
  {
    id: 5,
    name: 'Brand Comparison Report',
    filterName: 'brand-comparison',
    configs: {
      frequency: [frequencyEnums[0], frequencyEnums[1]],
      interval: [intervalEnums[2]],
      averageOf: [],
      compareStartDay: [],
      compareEndDay: [],
      hasNegativeSwitch: false,
      dateRange: true,
      reportPeriodDateType: 'month',
      reportPeriodDateFormat: 'MM-YYYY',
      reportPeriodDateRange: false,
      reportPeriodMaxMonthsBackward: 3,
      createOn: [],
      endDate: true,
    },
  },
  {
    id: 6,
    name: 'Buy Box Ownership Report',
    filterName: 'buy-box-ownership',
    configs: {
      frequency: [frequencyEnums[1]],
      interval: [intervalEnums[0]],
      averageOf: [averageOfEnums[0], averageOfEnums[1]],
      compareStartDay: [],
      compareEndDay: [],
      hasNegativeSwitch: false,
      dateRange: false,
      createOn: [],
      endDate: true,
    },
  },
  {
    id: 8,
    name: 'SKU Performance Detail Report',
    filterName: 'sku-performance-detail',
    configs: {
      frequency: [frequencyEnums[1]],
      interval: [intervalEnums[0]],
      averageOf: [],
      compareStartDay: [],
      compareEndDay: [],
      hasNegativeSwitch: true,
      dateRange: false,
      createOn: [],
      endDate: true,
    },
  },
];

import axios from 'axios';
import storeX from '../store/store';

const baseURL = process.env.VUE_APP_SERVICE_URL;

export default {
  bearer: {
    async post(
      params,
      urlDetail,
      canceler,
      isVendorCall = false,
      isWalmartCall = false
    ) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const store = urlParams.get('store');
      const marketplace = urlParams.get('marketplace');

      let defaultMarketplace = '';
      let defaultStore = '';

      if (!isVendorCall && !isWalmartCall) {
        if (!store || !marketplace) {
          defaultMarketplace = localStorage.getItem('marketplace');
          defaultStore = localStorage.getItem('store');
        } else {
          defaultMarketplace = marketplace;
          defaultStore = store;
        }
      }

      let obj = { ...params };

      if (params) {
        if (
          urlDetail ===
            'supply-chain/3pl/purchase-order/get-item-list-to-excel' ||
          urlDetail === 'user/link-account-seller-api' ||
          urlDetail === 'user/free-analytics-link-account-seller-api' ||
          urlDetail === 'advertising/vendor-register-profiles' ||
          urlDetail === 'advertising/register' ||
          urlDetail === 'advertising/vendor-register-profile-selection' ||
          urlDetail === 'vendor/link-account' ||
          urlDetail == 'store/store-expense-list' ||
          urlDetail == 'store/save-store-expense' ||
          urlDetail == 'store/update-store-expense' ||
          urlDetail == 'store/delete-store-expense' ||
          urlDetail == 'walmart/link-account' ||
          urlDetail == 'advertising/get-overall-performance-data'
        ) {
          obj['marketplace'] = params.marketplace;
          obj['sellerId'] = params.sellerId;
        } else {
          obj['marketplace'] = defaultMarketplace;
          obj['sellerId'] = defaultStore;
        }
        delete obj.initialLinkAccount;
      }

      if (
        urlDetail === 'vendor/link-account' ||
        urlDetail === 'advertising/get-vendor-overall-data' ||
        isVendorCall
      ) {
        delete obj.sellerId;
      }
      if (urlDetail === 'walmart-store/walmart-amazon-store-mapping') {
        delete obj.sellerId;
        delete obj.marketplace;
        obj = params;
      }
      if (isVendorCall) {
        const vuexStore = await storeX();
        const activeVendorStore = vuexStore.getters['vendor/activeStore'];
        obj.marketplace = activeVendorStore?.marketplace;
        obj.vendorId = activeVendorStore?.vendorId;
        if (!obj.vendorId) return;
      }
      if (isWalmartCall) {
        const vuexStore = await storeX();
        const activeWalmartStore = vuexStore.getters['walmart/activeStore'];
        const urlParams = new URLSearchParams(queryString);
        const store = urlParams.get('store');
        obj.marketplace = activeWalmartStore?.marketplace;
        obj.partnerId =
          store ||
          activeWalmartStore?.partnerId ||
          activeWalmartStore?.PartnerId;
        delete obj.sellerId;
        if (!obj.partnerId) return;
      }

      return axios({
        method: 'post',
        mode: 'no-cors',
        url: baseURL + urlDetail,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Expose-Headers': '*',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: obj,
        cancelToken: canceler,
      }).catch(async (e) => {
        if (axios.isCancel(e)) return;
        storeX().then((store) => {
          if (e.response.status === 403) {
            store.dispatch('updateUserInfo', {
              readOnly: true,
            });
          } else {
            store.dispatch('updateUserInfo', {
              readOnly: false,
            });
          }
        });

        if (401 === e.response.status) {
          if (localStorage.getItem('accessToken')) {
            let urlDetail = 'user/logout';
            axios({
              method: 'post',
              mode: 'no-cors',
              url: baseURL + urlDetail,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Expose-Headers': '*',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
              cancelToken: canceler,
            })
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              })
              .then(function () {
                // always executed
              });

            localStorage.removeItem('accessToken');
            localStorage.removeItem('displayBanner');
            localStorage.removeItem('isProgress');
            localStorage.removeItem('token');
            localStorage.removeItem('sellerCentralPathes');
            localStorage.removeItem('sellerCentral');
            localStorage.removeItem('sellerCentralConsent');
            localStorage.removeItem('store');
            localStorage.removeItem('storeName');
            localStorage.removeItem('marketplace');
            localStorage.removeItem('region');
            localStorage.removeItem('marketplaceCode');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('storeInfo');
            localStorage.removeItem('trial');
            localStorage.removeItem('uploadInfo');
            localStorage.removeItem('marketplaceSize');
            localStorage.removeItem('isLoginFormConfirm');
            localStorage.removeItem('InvoicePdfList');
            localStorage.removeItem('ReceiptPdfList');
            localStorage.removeItem('activeVendorStore');
            localStorage.removeItem('selectedAdvertisingMarketplaceList');
            await this.$store.dispatch('userInfo/removeUserInfo');
            this.$store.state.AppActiveUser = {};
            this.$store.dispatch('pdfTemplate/setInvoicePdfArray', []);
            this.$store.dispatch('pdfTemplate/setReceiptPdfArray', []);
            this.$store.dispatch(
              'similarProduct/clearSimilarProductDownloadAndUpload'
            );
            this.$store.dispatch('skuPerformance/clearUploadProductCostTask');
            this.$store.dispatch('skuPerformance/clearProductCostDownload');
          }

          window.location = window.location.origin + '/login';
        }
      });
    },
    postFormData(data, urlDetail, settings = {}) {
      return axios.post(baseURL + urlDetail, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Expose-Headers': '*',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        },
        ...settings,
      });
    },
  },
  bearerWithoutSellerMarket: {
    async post(params, urlDetail, canceler) {
      return axios({
        method: 'post',
        mode: 'no-cors',
        url: baseURL + urlDetail,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Expose-Headers': '*',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: params,
        cancelToken: canceler,
      }).catch(async (e) => {
        if (axios.isCancel(e)) return;
        storeX().then((store) => {
          if (e.response.status === 403) {
            store.dispatch('updateUserInfo', {
              readOnly: true,
            });
          } else {
            store.dispatch('updateUserInfo', {
              readOnly: false,
            });
          }
        });

        if (401 === e.response.status) {
          if (localStorage.getItem('accessToken')) {
            let urlDetail = 'user/logout';

            axios({
              method: 'post',
              mode: 'no-cors',
              url: baseURL + urlDetail,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Expose-Headers': '*',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
              cancelToken: canceler,
            })
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              })
              .then(function () {
                // always executed
              });

            localStorage.removeItem('accessToken');
            localStorage.removeItem('displayBanner');
            localStorage.removeItem('isProgress');
            localStorage.removeItem('token');
            localStorage.removeItem('sellerCentralPathes');
            localStorage.removeItem('sellerCentral');
            localStorage.removeItem('sellerCentralConsent');
            localStorage.removeItem('store');
            localStorage.removeItem('storeName');
            localStorage.removeItem('marketplace');
            localStorage.removeItem('region');
            localStorage.removeItem('marketplaceCode');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('storeInfo');
            localStorage.removeItem('trial');
            localStorage.removeItem('uploadInfo');
            localStorage.removeItem('marketplaceSize');
            localStorage.removeItem('isLoginFormConfirm');
            localStorage.removeItem('InvoicePdfList');
            localStorage.removeItem('ReceiptPdfList');
            localStorage.removeItem('activeVendorStore');
            localStorage.removeItem('selectedAdvertisingMarketplaceList');
            await this.$store.dispatch('userInfo/removeUserInfo');
            this.$store.state.AppActiveUser = {};
            this.$store.dispatch('pdfTemplate/setInvoicePdfArray', []);
            this.$store.dispatch('pdfTemplate/setReceiptPdfArray', []);
            this.$store.dispatch(
              'similarProduct/clearSimilarProductDownloadAndUpload'
            );
            this.$store.dispatch('skuPerformance/clearUploadProductCostTask');
            this.$store.dispatch('skuPerformance/clearProductCostDownload');
          }

          window.location = window.location.origin + '/login';
        }
      });
    },
  },
  uploadCSV: {
    post(params, urlDetail) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const store = urlParams.get('store');
      const marketplace = urlParams.get('marketplace');

      var defaultMarketplace = '';
      var defaultStore = '';

      if (
        store === null ||
        store === undefined ||
        store === '' ||
        store === 'undefined' ||
        marketplace === null ||
        marketplace === undefined ||
        marketplace === 'undefined' ||
        marketplace === ''
      ) {
        defaultMarketplace = localStorage.getItem('marketplace');
        defaultStore = localStorage.getItem('store');
      } else {
        defaultMarketplace = marketplace;
        defaultStore = store;
      }

      var obj = params;

      if (params) {
        if (
          urlDetail !== 'user/link-account-seller-api' &&
          urlDetail !== 'user/free-analytics-link-account-seller-api'
        ) {
          obj['marketplace'] = defaultMarketplace;
          obj['sellerId'] = defaultStore;
        } else {
          obj['marketplace'] = params.marketplace;
          obj['sellerId'] = params.sellerId;
        }
      } else {
        obj = {};
        obj['marketplace'] = null;
        obj['sellerId'] = null;
      }

      if (params === null || obj.marketplace) {
        storeX().then((store) => {
          store.dispatch('updateUserInfo', {
            readOnly: false,
          });
        });
        return axios({
          method: 'post',
          mode: 'no-cors',
          url: baseURL + urlDetail,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Expose-Headers': '*',
            'Access-Control-Allow-Headers':
              'Origin, X-Requested-With, Content-Type, Accept',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          data: obj,
        }).catch(async (e) => {
          storeX().then((store) => {
            if (e.response.status === 403) {
              store.dispatch('updateUserInfo', {
                readOnly: true,
              });
            } else {
              store.dispatch('updateUserInfo', {
                readOnly: false,
              });
            }
          });

          if (401 === e.response.status) {
            if (localStorage.getItem('accessToken')) {
              let urlDetail = 'user/logout';

              axios({
                method: 'post',
                mode: 'no-cors',
                url: baseURL + urlDetail,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': '*',
                  'Access-Control-Allow-Credentials': 'true',
                  'Access-Control-Expose-Headers': '*',
                  'Access-Control-Allow-Headers':
                    'Origin, X-Requested-With, Content-Type, Accept',
                  Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
              })
                .then(function () {})
                .catch(function (error) {
                  console.log(error);
                })
                .then(function () {
                  // always executed
                });

              localStorage.removeItem('accessToken');
              localStorage.removeItem('displayBanner');
              localStorage.removeItem('isProgress');
              localStorage.removeItem('token');
              localStorage.removeItem('sellerCentralPathes');
              localStorage.removeItem('sellerCentral');
              localStorage.removeItem('sellerCentralConsent');
              localStorage.removeItem('store');
              localStorage.removeItem('storeName');
              localStorage.removeItem('marketplace');
              localStorage.removeItem('region');
              localStorage.removeItem('marketplaceCode');
              localStorage.removeItem('userInfo');
              localStorage.removeItem('storeInfo');
              localStorage.removeItem('trial');
              localStorage.removeItem('uploadInfo');
              localStorage.removeItem('marketplaceSize');
              localStorage.removeItem('isLoginFormConfirm');
              localStorage.removeItem('InvoicePdfList');
              localStorage.removeItem('ReceiptPdfList');
              localStorage.removeItem('activeVendorStore');
              localStorage.removeItem('selectedAdvertisingMarketplaceList');
              await this.$store.dispatch('userInfo/removeUserInfo');
              this.$store.state.AppActiveUser = {};
              this.$store.dispatch('pdfTemplate/setInvoicePdfArray', []);
              this.$store.dispatch('pdfTemplate/setReceiptPdfArray', []);
              this.$store.dispatch(
                'similarProduct/clearSimilarProductDownloadAndUpload'
              );
              this.$store.dispatch('skuPerformance/clearUploadProductCostTask');
              this.$store.dispatch('skuPerformance/clearProductCostDownload');
            }
            window.location = window.location.origin + '/login';
          }
        });
      } else {
        return null;
      }
    },
  },
  notBearer: {
    post(params, urlDetail) {
      let obj = params;

      return axios({
        url: baseURL + urlDetail,
        method: 'post',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          'Access-Control-Allow-Methods': 'POST',
        },
        data: obj,
      });
    },
  },
  excelFile: {
    post(params, urlDetail) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const store = urlParams.get('store');
      const marketplace = urlParams.get('marketplace');

      var defaultMarketplace = '';
      var defaultStore = '';

      if (
        store === null ||
        store === undefined ||
        store === '' ||
        marketplace === null ||
        marketplace === undefined ||
        marketplace === ''
      ) {
        defaultMarketplace = localStorage.getItem('marketplace');
        defaultStore = localStorage.getItem('store');
      } else {
        defaultMarketplace = marketplace;
        defaultStore = store;
      }

      var obj = params;

      if (params) {
        if (
          urlDetail !== 'user/link-account-seller-api' &&
          urlDetail !== 'user/free-analytics-link-account-seller-api'
        ) {
          obj['marketplace'] = defaultMarketplace;
          obj['sellerId'] = defaultStore;
        } else {
          obj['marketplace'] = params.marketplace;
          obj['sellerId'] = params.sellerId;
        }
      } else {
        obj = {};
        obj['marketplace'] = null;
        obj['sellerId'] = null;
      }

      if (params === null || obj.marketplace) {
        storeX().then((store) => {
          store.dispatch('updateUserInfo', {
            readOnly: false,
          });
        });
        return axios({
          method: 'post',
          mode: 'no-cors',
          url: baseURL + urlDetail,
          responseType: 'arraybuffer',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
              'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Credentials': 'true',
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Access-Control-Allow-Methods': 'POST',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          data: obj,
        }).catch(async (e) => {
          storeX().then((store) => {
            if (e.response.status === 403) {
              store.dispatch('updateUserInfo', {
                readOnly: true,
              });
            } else {
              store.dispatch('updateUserInfo', {
                readOnly: false,
              });
            }
          });

          if (401 === e.response.status) {
            if (localStorage.getItem('accessToken')) {
              let urlDetail = 'user/logout';
              axios({
                method: 'post',
                mode: 'no-cors',
                url: baseURL + urlDetail,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Headers':
                    'Origin, X-Requested-With, Content-Type, Accept',
                  'Access-Control-Allow-Methods': 'POST',
                  Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
              })
                .then(function () {})
                .catch(function (error) {
                  console.log(error);
                })
                .then(function () {
                  // always executed
                });
              localStorage.removeItem('accessToken');
              localStorage.removeItem('displayBanner');
              localStorage.removeItem('isProgress');
              localStorage.removeItem('token');
              localStorage.removeItem('sellerCentralPathes');
              localStorage.removeItem('sellerCentral');
              localStorage.removeItem('sellerCentralConsent');
              localStorage.removeItem('store');
              localStorage.removeItem('storeName');
              localStorage.removeItem('marketplace');
              localStorage.removeItem('region');
              localStorage.removeItem('marketplaceCode');
              localStorage.removeItem('userInfo');
              localStorage.removeItem('storeInfo');
              localStorage.removeItem('trial');
              localStorage.removeItem('uploadInfo');
              localStorage.removeItem('marketplaceSize');
              localStorage.removeItem('isLoginFormConfirm');
              localStorage.removeItem('InvoicePdfList');
              localStorage.removeItem('ReceiptPdfList');
              localStorage.removeItem('activeVendorStore');
              localStorage.removeItem('selectedAdvertisingMarketplaceList');
              await this.$store.dispatch('userInfo/removeUserInfo');
              this.$store.state.AppActiveUser = {};
              this.$store.dispatch('pdfTemplate/setInvoicePdfArray', []);
              this.$store.dispatch('pdfTemplate/setReceiptPdfArray', []);
              this.$store.dispatch(
                'similarProduct/clearSimilarProductDownloadAndUpload'
              );
              this.$store.dispatch('skuPerformance/clearUploadProductCostTask');
              this.$store.dispatch('skuPerformance/clearProductCostDownload');
            }

            window.location = window.location.origin + '/login';
          }
        });
      } else {
        return null;
      }
    },
  },
  bearerCRM: {
    async post(params, urlDetail, canceler) {
      return axios({
        method: 'post',
        mode: 'no-cors',
        url: baseURL + urlDetail,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Expose-Headers': '*',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: params,
        cancelToken: canceler,
      }).catch(async (e) => {
        if (axios.isCancel(e)) return;

        if (e.response.status === 403) {
          window.location = window.location.origin + '/admin-dashboard';
        }

        if (401 === e.response.status) {
          if (localStorage.getItem('accessToken')) {
            let urlDetail = 'user/logout';

            axios({
              method: 'post',
              mode: 'no-cors',
              url: baseURL + urlDetail,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Expose-Headers': '*',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
              cancelToken: canceler,
            })
              .then(function () {})
              .catch(function (error) {
                console.log(error);
              })
              .then(function () {
                // always executed
              });

            localStorage.removeItem('accessToken');
            localStorage.removeItem('displayBanner');
            localStorage.removeItem('isProgress');
            localStorage.removeItem('token');
            localStorage.removeItem('sellerCentralPathes');
            localStorage.removeItem('sellerCentral');
            localStorage.removeItem('sellerCentralConsent');
            localStorage.removeItem('store');
            localStorage.removeItem('storeName');
            localStorage.removeItem('marketplace');
            localStorage.removeItem('region');
            localStorage.removeItem('marketplaceCode');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('storeInfo');
            localStorage.removeItem('trial');
            localStorage.removeItem('uploadInfo');
            localStorage.removeItem('marketplaceSize');
            localStorage.removeItem('isLoginFormConfirm');
            localStorage.removeItem('InvoicePdfList');
            localStorage.removeItem('ReceiptPdfList');
            localStorage.removeItem('activeVendorStore');
            localStorage.removeItem('selectedAdvertisingMarketplaceList');
            await this.$store.dispatch('userInfo/removeUserInfo');
            this.$store.state.AppActiveUser = {};
            this.$store.dispatch('pdfTemplate/setInvoicePdfArray', []);
            this.$store.dispatch('pdfTemplate/setReceiptPdfArray', []);
            this.$store.dispatch(
              'similarProduct/clearSimilarProductDownloadAndUpload'
            );
            this.$store.dispatch('skuPerformance/clearUploadProductCostTask');
            this.$store.dispatch('skuPerformance/clearProductCostDownload');
          }

          window.location = window.location.origin + '/login';
        }
      });
    },
  },
};

export default {
  SET_RULERESULTSFILTERREADY(state, payload) {
    state.isRuleResultsFilterReady = payload;
  },
  SET_ACTIVE_RULE_TAB(state, payload) {
    state.activeRuleTab = payload;
  },
  SET_RULERESULTSTABLEDATA(state, payload) {
    state.ruleResultsTableData = payload;
  },

  SET_RULERESULTSTABLECONFIG(state, payload) {
    state.ruleResultsTableConfig = payload;
  },

  SET_RULERESULTSPAGESIZE(state, payload) {
    state.ruleResultsPageSize = payload;
  },

  SET_RULERESULTDETAILS(state, payload) {
    state.ruleResultFilters = payload;
  },

  SET_RULERESULTDETAIL(state, payload) {
    state.ruleResultDetail = payload;
  },

  SET_TABLEDATA(state, payload) {
    state.tableData = payload;
  },

  SET_TABLECONFIG(state, payload) {
    state.tableConfig = payload;
  },

  SET_HISTORYTABLECONFIG(state, payload) {
    state.historyTableConfig = payload;
  },

  SET_FILTER(state, payload) {
    state.filter = payload;
  },

  SET_FILTERPRESELECT(state, payload) {
    state.filterPreSelect = payload;
  },

  SET_SORT(state, payload) {
    state.sort = payload;
  },

  SET_DATERANGE(state, payload) {
    state.dateRange = payload;
  },

  SET_DASHBOARD_DATERANGE(state, payload) {
    state.dashboardDateRange = payload;
  },

  SET_DASHBOARD_DATERANGE_TYPE(state, payload) {
    state.dashboardDateRangeType = payload;
  },

  SET_PAGEINFO(state, payload) {
    state.pageInfo = payload;
  },

  SET_HISTORYPAGEINFO(state, payload) {
    state.historyPageInfo = payload;
  },

  SET_ACTIVETAB(state, payload) {
    state.activeTab = payload;
  },

  SET_SERVICENOTIFY(state, payload) {
    state.serviceNotify = payload;
  },

  SET_TOOLBARDATA(state, payload) {
    state.toolbarData = payload;
  },

  SET_TOOLBAROPTIONS(state, payload) {
    state.toolbarOptions = payload;
  },

  SET_UPDATEINFORMATION(state, payload) {
    state.updateInformation = payload;
  },

  SET_PAGEOPTIONS(state, payload) {
    state.pageOptions = payload;
  },

  SET_AUTOMATION(state, payload) {
    state.automation = payload;
  },

  SET_ADDITIONALFEATURES(state, payload) {
    state.additionalFeatures = payload;
  },

  SET_UPDATEHISTORY(state, payload) {
    state.updateHistory = payload;
  },

  SET_CURRENCY(state, payload) {
    state.dashboardData.currency = payload;
  },

  SET_LAST_PERIOD_DIFFERENCE(state, payload) {
    state.dashboardData.LastPeriodDifference = payload;
  },

  SET_TABLE_HEADERS(state, payload) {
    state.dashboardData.TableHeaders = payload;
  },

  SET_PERIOD_HEADERS(state, payload) {
    state.dashboardData.PeriodHeaders = payload;
  },

  SET_TABLE_PERIODS(state, payload) {
    state.dashboardData.TablePeriods = payload;
  },

  SET_TABLE_CHART(state, payload) {
    state.dashboardData.TableChart = payload;
  },

  SET_SELECTED_PERIOD(state, payload) {
    state.dashboardData.selectedPeriod = payload;
  },

  SET_LAST_SYNC_TIME(state, payload) {
    state.dashboardData.lastSyncTime = payload;
  },

  SET_CONVERSION_RATE(state, payload) {
    state.dashboardData.conversionRate = payload;
  },

  SET_ORDERS_AND_PERFORMANCE(state, payload) {
    state.dashboardData.ordersAndPerformance = payload;
  },

  SET_CLICK_SESSIONS_AND_CTR(state, payload) {
    state.dashboardData.clickSessionsAndCtr = payload;
  },

  SET_AVERAGE_ORDER_VALUE(state, payload) {
    state.dashboardData.averageOrderValue = payload;
  },

  SET_SALES_SPEND_AND_PERFORMANCE(state, payload) {
    state.dashboardData.salesSpendAndPerformance = payload;
  },

  SET_PERIOD_DATES(state, payload) {
    state.dashboardData.periodDates = payload;
  },

  SET_PRODUCT_LEVEL_SETTINGS_DETAIL(state, payload) {
    state.ProductLevelSettings.selectedDetail = payload;
  },

  SET_PRODUCT_LEVEL_SETTINGS_FILTER(state, payload) {
    state.ProductLevelSettings.filter = {
      ...state.ProductLevelSettings.filter,
      ...payload,
    };
  },

  REMOVE_PRODUCT_LEVEL_SETTINGS_FILTER(state, payload) {
    state.ProductLevelSettings.filter = Object.keys(
      state.ProductLevelSettings.filter
    )
      .filter((objKey) => objKey !== payload)
      .reduce((newObj, key) => {
        newObj[key] = state.ProductLevelSettings.filter[key];
        return newObj;
      }, {});
  },

  SET_PRODUCT_LEVEL_SETTINGS_FILTER_SEARCH_TEXT(state, payload) {
    state.ProductLevelSettings.searchText = payload;
  },

  SET_ADV_DATA_STATUS(state, payload) {
    state.advDataStatus = payload;
  },

  SET_FORECAST_REQUEST_STATUS(state, payload) {
    state.forecastRequestStatus = payload;
  },
  SET_FORECAST_DATA(state, payload) {
    state.forecastData = payload;
  },

  SET_DASHBOARD_AVAILABLE_DATE_RANGE(state, payload) {
    state.advertisingAvailableDateRange = payload;
  },

  SET_ANALYTICS_SMART_FILTERS(state, payload) {
    state.analyticsSmartFilters = payload;
  },

  SET_SELECTED_ANALYTICS_SMART_FILTERS(state, payload) {
    state.selectedAnalyticsSmartFilter = payload;
  },

  SET_ANALYTICS_CHART_DATA(state, payload) {
    state.analyticsChartData = payload;
  },

  SET_DASHBOARD_TREND_REPORT_TAB(state, payload) {
    state.dashboardTrendReportTab = {
      ...state.dashboardTrendReportTab,
      ...payload,
    };
  },

  SET_TRENDREPORT_DATERANGE(state, payload) {
    state.trendReportdateRange = payload;
  },

  SET_TRENDREPORT_OPTIONS(state, payload) {
    state.trendReportChartOptions = {
      ...state.trendReportChartOptions,
      [payload.key]: payload.option,
    };
  },

  SET_APP_ACTIVE_STORE_TRIAL(state, payload) {
    state.appActiveStoreTrial = payload;
  },
};
